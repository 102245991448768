<template>
  <div >
    <div class="collect" >
        <div class="img">
          <img :src="subLister.cover[0]" alt="">
        </div>
      <!-- 遮罩层 -->
      <div class="floor">
        <div class="floor_warp1">
          <div class="iconimg"></div>
          <p id="active" style="padding:0.05rem"><span @click="dialogVisible = true"> 订阅规则</span></p>
        </div>

        <!-- 图片 -->
        <div class="floor_warp2">
          <div class="floor_warp2er" >
            <div class="warp2_img">
              <img :src="subLister.cover[0]" alt="">
            </div><!-- 一张大图片 -->
            <div class="warp2_imger"><!-- 两张小图片 -->
              <div class="fl_imger">
                <img :src="subLister.cover[1]" alt="">
              </div>
              <div>
                <img :src="subLister.cover[2]" alt="">
              </div>
            </div>
          </div>
          <div class="fl_zi">
            <p class="fl_zier">{{subLister.name}}</p>
            <div class="fl_ti">
                <div>
                    <div class="head">
                      <img :src="subLister.creatorInfo.photo" alt="">
                    </div> 
                    &nbsp; {{subLister.creatorInfo.name}}
                </div>
                <!-- <p><span class="guan"></span> 老阿姨</p>
                <p><span class="tu"></span> 老阿姨</p> -->
            </div>
          </div>
        </div>

        <div class="floor_warp3">
          <p>{{subLister.description}}
          </p>
        </div>

        <div class="floor_warp1">
          <p class="warp1er">样张</p>
        </div>

        <!-- <div class="floor_warp4">
          <div  class="warp4_img" id="btn" @click="preview(index)" v-for="(item,index) in subList.theme_cover" :key="index">
            <img :src="item" alt="">
          </div>
        </div> -->

      </div>
      <!-- 订阅规则的弹出框 -->
      <el-dialog :visible.sync="dialogVisible" width="30%" style="margin:0 !important"  fullscreen="fullscreen" >
        <div class="dilog">
          <div>
            <p class="title">订阅规则</p>
            <p>&nbsp;·&nbsp;订阅资料即可获取当前所有资料</p>
            <p>&nbsp;·&nbsp;资料将在订阅时间内持续更新,订阅时间结束后需续订方可获取后续资料文件</p>
            <p>&nbsp;·&nbsp;订阅文件可在几何蛙微信小程序、移动端、PC端内查看</p>
            <p>&nbsp;·&nbsp;订阅文件需收藏后方可下载</p>
          </div>
        </div>
      </el-dialog>
      
      <!-- 照片预览的弹出框 -->
      <!-- <el-dialog :visible.sync="dialog" width="30%" style="margin:0 !important"  fullscreen="fullscreen" :show-close="show" >
        <div class="cha" @click="close()">×</div>
        <div class="dilog" v-if="dia">
          <swiper ref="mySwiper" :options="swiperOptions" >     
              <swiper-slide v-for="(item,index) in subList.theme_cover" :key="index">
                <img class="swiper-img" :src="item" alt="">
              </swiper-slide>
          </swiper>
          
        </div>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'CollectPayment',
  components: {
      Swiper,
      SwiperSlide
  },
  props:['subLister'],
  data(){
    return{
      subListStar:false,
      show:false,
      dia:true,
      dialogVisible: false,//订阅规则弹框的显示隐藏
      fullscreen:true, //弹框全屏的布尔值
      dialog:false, //样张弹框的显示隐藏
      swiperOptions: {
                    // slidesPerView: 4.5,
                    // grabCursor:true,
                    // spaceBetween: 50,
                    centeredSlides: true,
                    // speed: 5000,
                    // autoplay: {
                    //     delay: 1000,
                    //     disableOnInteraction: false,
                    //     waitForTransition: false,
                    // },
                    loop: true,
                    initialSlide :"",
                    // pagination: {
                    //     el: '.swiper-pagination',
                    //     clickable: true
                    // }
                }
    }
  },
  // watch: {
  //   subList: function(aft, bef) {
  //     console.log(aft, bef);
  //   }
    
  // },
  created(){
    // if (subLister) {
    //   this.subListStar = true
    // }
    // console.log(this.subList,"123");
    // console.log(this.subList.content[0].cover[0],"123");
  },
  mounted(){
    // console.log(this.subList,"45");
    // this._initSwiper();
  },
  methods:{
    preview(index){
      // console.log(index); 
      this.dia=true
      // console.log(this.dia);
      this.swiperOptions.initialSlide = index
      this.dialog = true
      // $('#btn').click(function(){
      //   console.log(123);
      //   this.$refs.mySwiper.slideToLoop(index, 1000, false);//切换到第一个slide，速度为1秒
      // })
    },
    close(){
      // console.log(456);
      this.dialog = false
      this.dia = false
    }
  }
  
}
</script>

<style scoped lang="scss">
.iconimg{
  width: 15px;
  height: 15px;
  padding: 0;
  margin-left: 10px;
  // background-color: #fff;
  background: url("../assets/img/icon1.png") no-repeat;
  background-size: 100% 100%;
}
img{
  width: 100%;
  height: 100%;
}
.dilog div{
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}
.swiper-box{
margin: 0;
padding: 0 !important;
}
.swiper-container{
  padding: 0 !important;
}
.swiper-slide{
  padding: 0 !important;
}
.swiper-slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.collect{
  width: 100%;
  height: 6.66rem;
  position: relative;
  
}
.img{
  width: 100%;
  height: 6.66rem;
  position: absolute;
  // background: url("http://pic1.win4000.com/m00/77/31/9bbeba52eb297eca1100680e21190b5c.jpg") no-repeat;
  // background-size: 100% 100%;
  filter: blur(5px)
}
.img img{
  width: 100%;
  height: 100%;
}
.floor{
  position: absolute;
  width: 100%;
  height: 6.66rem;
  background-color: rgba(0,0,0,0.5);
  
}
.floor_warp1{
  height: 0.3rem;
  line-height: 0.4rem;
  // background-color: pink;
  color: #9e9e9b;
  font-size: 0.15rem;
  display: flex;
  align-items: center;
  padding-top: 5px;
  p{
    padding-left: 0.1rem;
  }
}
.floor_warp2{
  height: 2rem;
  margin-top: 75px;
  // background-color: seagreen;
  
}
.floor_warp2er{
  height: 1.2rem;
  // background-color: bisque;
  display: flex;
  justify-content: center;
}
.warp2_img{
  width: 1.2rem;
  height: 1.2rem;
  // background-color: red;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
}
.warp2_imger{
  width: 0.6rem;
  height: 1.2rem;
  margin-left: 0.05rem;
  // background-color: blueviolet;
  div{
    height: 0.57rem;
    // background-color: #fff;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.fl_imger{
  margin-bottom: 0.05rem;
}
.fl_zi{
  height: 0.8rem;
  // background-color: pink;
  text-align: center;
  .fl_zier{
    padding-top: 0.1rem;
    font-weight: bold;
    color: #fff;
    font-size: 0.2rem;
  }
  .fl_ti{
        height: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        div{
            display: flex;
            align-items: center;
            color: rgba(255,255,255,0.8);
            .head{
                width: 18px;
                height: 18px;
                border-radius: 50%;
                overflow: hidden;
                // background-color: #9E573E;
            }
        } 
        p{
            margin: 0.1rem;
            color: #908F8E;
            display: flex;
            align-items: center;
            .guan{
                display: inline-block;
                width: 15px;
                height: 15px;
                // background-color: red;
                background: url("../assets/img/icon2.png") no-repeat;
                background-size: 100% 100%;
            }
            .tu{
                display: inline-block;
                width: 15px;
                height: 15px;
                // background-color: red;
                background: url("../assets/img/icon3.png") no-repeat;
                background-size: 100% 100%;
            }
            
        }
    }
}
.floor_warp3{
  // height: 0.85rem;
  height: auto;
  color: #a2a19d;
  font-size: 0.13rem;
  // background-color: red;
  p{
    padding: 0.1rem;
  }
}
.floor_warp4{
  height: auto;
  // background-color: #9e9e9b;
  display: flex;
  // justify-content: space-around;
  flex-flow: wrap;

  .warp4_img{
    width: 0.8rem;
    height: 0.58rem;
    margin:  0.15rem 0 0 0.1rem ;
    // background-color: olivedrab;
    // background: url("http://pic1.win4000.com/m00/77/31/9bbeba52eb297eca1100680e21190b5c.jpg") no-repeat;
    // background-size: 100% 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
::v-deep .el-dialog{
  background-color: rgba(0,0,0,0.8);
  // opacity: 0.9;
  
}
::v-deep .el-dialog__headerbtn .el-dialog__close{
  color: #fff;
  font-size: 0.23rem;
}
.dilog{
  width: 100%;
  height: 2.2rem;
  border-radius: 0.05rem;
  margin-top: 150px;
  // background-color: #301E1B;
  // background-color: #fff;
  color: #fff;
  div{
    width: 90%;
    height: 1.9rem;
    // background-color: pink;
    // padding: 0.15rem;
    padding: 0.1rem 0 0 0.1rem;
    p{
      margin-top: 0.1rem;;
      font-size: 0.13rem;
      font-weight: lighter;
    }
    .title{
      font-size: 0.16rem;
      margin-bottom: 0.1rem;
    }
  }
}
.warp1er{
  color: rgba(255,255,255,0.8);
}
.cha{
  color: #fff;
  font-size: 35px;
  margin-top: -50px;
  margin-left: 313px;
}
</style>

