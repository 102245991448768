import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from './api/api'
import $ from 'jquery'
import "../src/app/app.js";
import Alert from '../src/components/message/index';
import waterfall from 'vue-waterfall2'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import './assets/css/swiper-bundle.min.css'
import SwiperCore, { Navigation, Autoplay, Pagination, Scrollbar, A11y ,EffectFade,} from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination, Scrollbar, A11y,EffectFade,]);
Vue.use(VueAwesomeSwiper)


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Alert);
Vue.use(waterfall)
Vue.prototype.$api = api
Vue.prototype.$ = $
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')