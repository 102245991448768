<template>
  <div class="home">
    <CollectPayment :subLister = "subLister" v-if="sub"></CollectPayment>
    <!-- <Collecttop/> -->
    <div class="bottom" @click="btn()">
      <p>支付￥{{subList.price}}/年</p>
      <p>订阅文件版</p>
    </div>
    <!-- <div class="collecttop">
        <img src="../assets/img/back.png" alt="">
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import CollectPayment from '@/components/CollectPayment.vue'
// import Collecttop from '@/components/Collecttop'
export default {
  name: 'ToSubscribe',
  components: {
    CollectPayment,
    // Collecttop
  },
  data(){
    return{
      subList:[],
      subLister:[],
      sub:false,
      goodID:"623029a256811f74ee908ad0", // TODO: 需要替换成真实订单
    }
  },
  created(){
    this.goodID=this.$route.query.themeId
    // console.log('====================',this.$route.query.themeId);
    // console.log(this._isMobile())
    if(!this._isMobile()){
      console.log("https://www.geomfrog.cn/subLogin?themeId="+this.goodID);
      window.location.href="https://www.geomfrog.cn/subLogin?themeId="+this.goodID
      return
    } else {
        this.$api.getsubscribe({type:"get", uid: 0, value:this.goodID,}).then((res) => {
          this.subList = res.data
          // console.log(this.subList.value,"====>");
          this.getFolder2(this.subList.value)
          
        })
    }
  },
  methods:{
    btn(){
      console.log(123);
      let bigbigbig_user =  localStorage.getItem('bigbigbig_user')
      if (!!bigbigbig_user &&  bigbigbig_user.uid !== '' ){
          this.$router.push({path:'/payment',query:{themeId:this.goodID}})
      }else{
        // this.$router.push({name:'Login',query:{type:2,themeId:this.goodID}})
        this.$router.push({name:'Login',query:{reurl:"/payment?themeId="+this.goodID}})
      }
      
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getFolder2(folderId){
      this.$api.getFolder2({uid: 0,folderId:folderId,page:0,pageSize:50}).then((res)=>{
        this.subLister = res.data
        this.subLister.cover = this.subLister.cover.split()
        console.log(this.subLister);
        this.sub = true
      })
    }
  }
}
</script>
<style scoped lang="scss">
.home{
  width: 100%;
  height: auto;
  position: relative;

  /* background-color: red; */
}
.bottom{
  width: 91%;
  height: 0.55rem;
  background-color: #9e573e;
  position: absolute;
  bottom: 0.21rem;
  left: 0.15rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:0.05rem ;
  p{
    margin-left: 0.1rem;
  }
}
.collecttop{
    width: 100%;
    height: 0.75rem;
    position: absolute;
    bottom: 0;
    // background-color: #301e1b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #977968;
}

.collecttop img{
    width: 100%;
    height: 100%;
}
</style>
