import Vue from 'vue'
import VueRouter from 'vue-router'
import ToSubscribe from '../views/ToSubscribe.vue'

Vue.use(VueRouter)

const routes = [{
        //登录
        path: '/Login',
        name: 'Login',
        component: () => import( /* webpackChunkName: "Login" */ '../views/Login.vue')
    },
    // {
    //     //订阅
    //     path: '/ToSubscribe',
    //     name: 'ToSubscribe',
    //     component: ToSubscribe
    // },
    // {
    //     //支付页
    //     path: '/payment',
    //     name: 'payment',
    //     component: () => import( /* webpackChunkName: "payment" */ '../views/ToSubscribe/payment.vue')
    // },
    // {
    //     // 支付成功
    //     path: '/paysuccess',
    //     name: 'paysuccess',
    //     component: () => import( /* webpackChunkName: "paysuccess" */ '../views/ToSubscribe/paysuccess.vue')
    // },
    // {
    //     // vip页
    //     path: '/vip',
    //     name: 'vip',
    //     component: () => import( /* webpackChunkName: "vip" */ '../views/vip.vue')
    // },
    // {
    //     // vip详情页
    //     path: '/viper',
    //     name: 'viper',
    //     component: () => import( /* webpackChunkName: "viper" */ '../views/vip/viper.vue')
    // },
    // {
    //     // 分享
    //     path: '/Collection',
    //     name: 'Collection',
    //     component: () => import( /* webpackChunkName: "Collection" */ '../views/Collection.vue'),
    //     meta: {
    //         title: '分享'
    //     }
    // },
    // {
    //     //分享详情
    //     path: '/folder/details',
    //     name: 'details',
    //     component: () => import( /* webpackChunkName: "details" */ '../views/Collection/details.vue')
    // },
    // {
    //     //分享图片详情
    //     path: '/figure',
    //     name: 'figure',
    //     component: () => import( /* webpackChunkName: "figure" */ '../views/Collection/figure.vue')
    // },
    {
        //了解几何蛙
        path: '/detailpage',
        name: 'detailpage',
        component: () => import( /* webpackChunkName: "detailpage" */ '../components/DetailPage.vue')
    },
    // {
    //     //收藏成功、失败
    //     path: '/collsucc',
    //     name: 'collsucc',
    //     component: () => import( /* webpackChunkName: "collsucc" */ '../views/Collection/collsucc.vue')
    // },
    // {
    //     //收藏成功、失败
    //     path: '/save',
    //     name: 'save',
    //     component: () => import( /* webpackChunkName: "save" */ '../views/Save.vue')
    // },
    // {
    //     //收藏单个文件
    //     path: '/ShareFiles',
    //     name: 'ShareFiles',
    //     component: () => import( /* webpackChunkName: "ShareFiles" */ '../views/Collection/ShareFiles.vue')
    // },
    // {
    //     //收藏单个文件
    //     path: '/share',
    //     name: 'share',
    //     component: () => import( /* webpackChunkName: "share" */ '../views/Collection/share.vue')
    // },
    // {
    //     //新版订阅
    //     path: '/subscription',
    //     name: 'subscription',
    //     component: () => import( /* webpackChunkName: "subscription" */ '../views/subscription/subscription.vue')
    // },
    // {
    //     //新版订阅树
    //     path: '/subtree',
    //     name: 'subtree',
    //     component: () => import( /* webpackChunkName: "subscription" */ '../views/subscription/subtree.vue')
    // },
    // {
    //     //新版订阅树内
    //     path: '/subfolder',
    //     name: 'subfolder',
    //     component: () => import( /* webpackChunkName: "subscription" */ '../views/subscription/subfolder.vue'),
    //     meta:{
    //         keepAlive:true,
    //     }
    // },
    // {
    //     //新版订阅文件
    //     path: '/subfile',
    //     name: 'subfile',
    //     component: () => import( /* webpackChunkName: "subscription" */ '../views/subscription/subfile.vue')
    // },
    {
        //新版分享
        path: '/sharefolder',
        name: 'sharefolder',
        component: () => import( /* webpackChunkName: "subscription" */ '../views/share/sharefolder.vue')
    },
    {
        //新版分享下一层
        path: '/sharing',
        name: 'sharing',
        component: () => import( /* webpackChunkName: "subscription" */ '../views/share/sharing.vue')
    },
    {
        //新版分享树
        path: '/sharetree',
        name: 'sharetree',
        component: () => import( /* webpackChunkName: "subscription" */ '../views/share/sharetree.vue')
    },
    {
        //新版分享图片层
        path: '/sharefile',
        name: 'sharefile',
        component: () => import( /* webpackChunkName: "sharefile" */ '../views/share/sharefile.vue')
    },
    {
        //新版分享进入个人文件版
        path: '/shareparent',
        name: 'shareparent',
        component: () => import( /* webpackChunkName: "shareparent" */ '../views/share/shareparent.vue')
    },{
        //新版分享开通vip
        path: '/vip',
        name: 'vip',
        component: () => import( /* webpackChunkName: "vip" */ '../views/share/vip.vue')
    },{
        //新版分享进入个人文件版的下一层页面
        path: '/shareparenter',
        name: 'shareparenter',
        component: () => import( /* webpackChunkName: "shareparenter" */ '../views/share/shareparenter.vue')
    },
    // {
    //     //测试页面
    //     path: '/asdsad',
    //     name: 'asdsad',
    //     component: () => import( /* webpackChunkName: "asdsad" */ '../views/share/asdsad.vue')
    // },
    {
        //普通会员与金会员的对比
        path: '/pcvip',
        name: 'pcvip',
        component: () => import( /* webpackChunkName: "asdsad" */ '../views/vip/pcvip.vue')
    },{
        //购买容量包
        path: '/capacity',
        name: 'capacity',
        component: () => import( /* webpackChunkName: "capacity" */ '../views/share/capacity.vue')
    },
    // {
    //     //支付宝支付成功
    //     path: '/vipshare',
    //     name: 'vipshare',
    //     component: () => import( /* webpackChunkName: "capacity" */ '../views/share/vipshare.vue')
    // },
    {
        //多个文件分享
        path: '/multiplefiles',
        name: 'multiplefiles',
        component: () => import( /* webpackChunkName: "multiplefiles" */ '../views/share/multiplefiles.vue')
    },{
        //官网首页
        path: '/',
        name: 'support',
        component: () => import( /* webpackChunkName: "support" */ '../views/home/support.vue')
    },{
        //官网内部页
        path: '/supporter',
        name: 'supporter',
        component: () => import( /* webpackChunkName: "supporter" */ '../views/home/supporter.vue')
    },{
        //官网
        path: '/home',
        name: 'home',
        component: () => import( /* webpackChunkName: "home" */ '../views/home/home.vue')
    },{
        //官网下载
        path: '/homepage',
        name: 'homepage',
        component: () => import( /* webpackChunkName: "homepage" */ '../views/home/homepage.vue')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router