import axios from 'axios'
import qs from 'qs'
const CryptoJS = require('crypto-js');

// 签名
// const SECRET_ID = "AKIDghbyenvxb4xcjfsi62chInMru9joSsn1y5w2";
// const SECRET_KEY = "3wrs8R2xF91vt6ixR4fskkcq4hYI0h7g30XzFCMo";
const SecretId = 'APID88x5hQy2lbI02kESeq2jd9Lf8indid1pKqD0';
const SecretKey = '4Ewvft2dzx3140i0an06djlv422YAlj161nmtxiu';
// const Source = "Search";
const headerObj = { "Accept": 'application/json', 'Content-Type': 'application\/x-www-form-urlencoded' };
/*function getAuthorization(dateStr, source) {
    // const sourceStr = "x-date: " + dateStr + "\n" + "source: " + source;
    // const signature = CryptoJS.HmacSHA1(sourceStr, SECRET_KEY).toString()
    var auth = "hmac id=\"" + SECRET_ID + "\", algorithm=\"hmac-sha1\", headers=\"x-date source\", signature=\"";
    var signStr = "x-date: " + dateStr + "\n" + "source: " + source;
    var sign = CryptoJS.HmacSHA1(signStr, SECRET_KEY) //第一个参数为加密字符串，第二个参数为公共秘钥
    sign = CryptoJS.enc.Base64.stringify(sign)
    sign = auth + sign + "\""
    return sign;
}*/

function getHeader(data) {
    let dateTime = new Date().toUTCString();
    // let md5 = crypto.createHash('md5').update(JSON.stringify(data)).digest('hex');
    let contentMD5 = ""; // Buffer.from(md5).toString('base64');
    let headers = { ...headerObj, ...{ 'Content-MD5': contentMD5, "x-date": dateTime , "client": 6} };
    let path = data.url;
    if (data.hasOwnProperty("data")) {
        // console.log("ssssssssssssssss",data.data);
        let param = getParams(qs.parse(data.data));
        if (param.length > 0) path = path + "?" + param;
    }
    let signing = [`x-date: ${dateTime}`, data.method.toUpperCase(), headers.Accept, headers['Content-Type'], contentMD5, path];
    // console.log("signing", signing);
    headers.Authorization = getSign(signing);
    // headers['Content-Type'] = 'application/json'
    return headers;
}
function getParams(data) {
    if (!data) return "";

    // 先对参数key，做unicode排序
    let keys = [];
    for (var key in data) {
        keys.push(key);
    }

    keys = keys.sort();
    let postData = {};
    for (var i in keys) {
        let key = keys[i];
        postData[key] = data[key];
    }

    let res = urlEncode(postData);
    if (res.length > 0) {
        res = res.substring(1, res.length);
    }

    return res;
}
function urlEncode(param, key, encode) {
    if (param == null) return '';
    var paramStr = '';
    var t = typeof (param);
    if (t == 'string' || t == 'number' || t == 'boolean') {
        if (param.toString() == "") {
            paramStr += '&' + key;
        } else {
            paramStr += '&' + key + '=' + param;
        }
    } else {
        for (var i in param) {
            var k = key == null ? i : key + '[' + i + ']';
            paramStr += urlEncode(param[i], k, encode);
        }
    }
    return paramStr;
}
function getSign(signarr) {
    let signingStr = signarr.join('\n');
    // console.log(signingStr)
    var signing = CryptoJS.HmacSHA1(signingStr, SecretKey);

    // var sign = Crypto.AES(signStr, SecretKey) //第一个参数为加密字符串，第二个参数为公共秘钥
    //console.log(sign.toString())
    signing = CryptoJS.enc.Base64.stringify(signing)
    // const signing = Crypto.HmacSHA1(signingStr, this.data.SecretKey);
    const sign = `hmac id="${SecretId}", algorithm="hmac-sha1", headers="x-date", signature="${signing}"`
    return sign;
}

/*axios环境参数配置*/
//环境判断


// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; //post请求头设置
//请求截拦
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token，token需要和后台商量好
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // config.headers.Authorization = 'Bearer ' + store.state.token;
        config.headers = getHeader(config)
        /*let nowDateStr = new Date().toUTCString()
        let Authorization = getAuthorization(nowDateStr, Source)
        // console.log(Authorization)
        config.headers = {
            'x-date': nowDateStr,
            'Source': Source,
            'Authorization': Authorization,
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }*/
        // config.headers.x-date = nowDateStr
        // config.headers.Source = Source
        return config;
    },
    error => {
        // console.log(error);
        return error;
    })

//数据拿到之后
axios.interceptors.response.use(response => {
    if (response.status === 200) {
        return response.data;
    } else {
        return response.data;
    }
},error => {
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    /*if (error.response.status === 401) {
        localStorage.removeItem('token')
        vm.$root.$emit('login',1)
    } else {
        return error.response;
    }*/
    return error.response;
});



export function GET(url, params, baseUrl) {
    return new Promise((resolve, reject) => {
        axios({
            method:'get',
            baseURL:baseUrl,
            url,
            params,
            timeout:15000,//响应时间
        }).then(res => {
            resolve(res);
        }).catch(err =>{
            reject(err)
        })
    })
}

export function POST(url, params, baseUrl) {
    return new Promise((resolve, reject) => {
        axios({
            method:'post',
            baseURL:baseUrl,
            url,
            data: qs.stringify(params),
            timeout:5000,//响应时间
        }).then(res => {
            resolve(res);
        }).catch(err =>{
            reject(err)
        })
    })
}

