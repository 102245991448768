import { GET, POST } from "./http";
// let baseUrl = ['/api1', '/api2']
// if (process.env.NODE_ENV === 'production') {  //生产环境
//     // baseUrl = ['https://imgapi.bigbigbig.cn/', 'https://service-mm0q8ntx-1256286586.sh.apigw.tencentcs.com/release/'];  //接口域名修改地址
//     baseUrl = ['https://imgapi.bigbigbig.cn/', 'https://service-gc5blo6l-1256286586.sh.apigw.tencentcs.com/release/'];  //接口域名修改地址
// }
let baseUrl = ['https://imgapi.bigbigbig.cn/', 'https://service-gc5blo6l-1256286586.sh.apigw.tencentcs.com/release/']; //接口域名修改地址

export default {
    /*个人信息相关*/
    getDiscoveryClassList: p => GET('/api/resource/categoryList', p, baseUrl[0]), // 获取分类
    getDiscoveryDeepFolder: p => GET('/api/resource/list', p, baseUrl[0]), // 主页获取图片集(六张)
    getDiscoveryCards: p => GET('/api/resource/detail', p, baseUrl[0]), // 获取文件夹中的图片
    getImgDetails: p => GET('/api/resource/file', p, baseUrl[0]), // 获取图片详情
    getSearchDetails: p => POST('/api/files/getCard', p, baseUrl[1]), // 获取图片详情
    // getDiscoveryFolder: p => POST('/api/resource/list', p, baseUrl), // 主页获取图片集
    collection: p => POST('/api/resource/collect', p, baseUrl[0]), // 收藏

    // getDiscoveryClassList: p => GET('/api/folders/discoveryLabelsNoAuth', p, baseUrl), // 获取分类
    // getDiscoveryFolder: p => POST('/api/folders/discoveryFromSearch/v4', p, baseUrl), // 主页获取图片集
    // getDiscoveryDeepFolder: p => POST('/api/folders/discoveryFromDeepfrog/v4', p, baseUrl), // 主页获取图片集(六张)
    // getDiscoveryCards: p => GET('/api/folders/discoveryCards', p), // 获取文件夹中的图片
    // getDiscoveryCards: p => GET('/api/folders/folderCards/v4', p, baseUrl), // 获取文件夹中的图片
    getSearchList: p => POST('/api/files/searchfilesbykey', p, baseUrl[1]), //搜索
    getImages: p => POST('/api/files/getimages', p, baseUrl[1]), //找相似
    // discoveryFolder: p => POST('/api/folders/discoveryFolder', p, baseUrl[1]),    //找相似

    //全球设计导航
    getweb: p => POST('/api/navigation/websitelist', p, baseUrl[1]),



    // 获取文件板信息
    getFolder2: p => POST("/api/folders/getFolderNoAuth", p, baseUrl[1]),

    // 获取文件板信息
    getFolder: p => POST("/api/folders/getFolder", p, baseUrl[1]),
    // 收藏文件板
    collectFolder: p => POST("/api/folders/operateFolder", p, baseUrl[1]),
    // 添加文件板
    addFolder: p => POST("/api/folders/addFolder", p, baseUrl[1]),
    // 添加团队文件板
    addTeamFolder: p => POST("/api/team/addFolder", p, baseUrl[1]),
    // 添加文件
    addFiles: p => POST("/api/files/addFiles", p, baseUrl[1]),
    // 添加团队文件
    addTeamFiles: p => POST("/api/team/addFiles", p, baseUrl[1]),
    // 获取文件
    getCard: p => POST("/api/files/getCard", p, baseUrl[1]),
    // 文件查询
    fileFilter: p => POST("/api/folders/filter", p, baseUrl[1]),

    // 获取文件板列表
    getMyspace: p => POST("/api/folders/getMyspace", p, baseUrl[1]),

    //h5移动端订阅
    getsubscribe: p => POST('/api/subscription/subscription', p, baseUrl[1]),
    //h5移动端登录
    getSmsCode: p => POST('/api/sms/smsCode', p, baseUrl[1]), //请求验证码
    getSmsCo: p => POST('/api/account/verifyPhone', p, baseUrl[1]), //校验手机号
    getlogin: p => POST('/api/account/login', p, baseUrl[1]), //登录
    //获取商品
    getgoods: p => POST('/api/goods/getGoods', p, baseUrl[1]),
    generateOrder: p => POST('/api/order/generateOrder', p, baseUrl[1]),
    getOrderStatus: p => POST('/api/order/getOrderStatus', p, baseUrl[1]),
    // 获取用户空间用量
    getuserStorage: p => POST('/api/user/userStorage', p, baseUrl[1]),
    //创建反馈
    addReport: p => POST('/api/universal/addReport', p, baseUrl[1]),
    
    


}