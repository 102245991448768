<template>
  <div id="app">
    <nav>
      <router-link to="/"></router-link>
      <!-- <router-link to="/Collection">About</router-link> -->
    </nav>
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #151413;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  font-size: 16px;
  height: 100%;
  // max-width: 400px;
}
* {
  margin: 0;
  padding: 0;
}

.el-loading-spinner .path {stroke: #D3844F!important; stroke-width: 4px;}
.el-loading-spinner .el-loading-text { color:#D3844F!important; font-weight: 600; }
.el-loading-mask { background-color: rgba(0,0,0,0.7) !important;}
.el-loading-mask.is-fullscreen .el-loading-spinner { margin-top: -80px !important; }
.el-message--success,.el-message--warning,.el-message--error{
  padding: 0 !important;
  min-width: 340px !important;
  height: 30px;
  font-size: 25px;

}

</style>
